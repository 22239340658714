.flag-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}