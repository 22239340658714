/* Modify the scrollbar appearance for WebKit (Chrome, Safari, Edge) */
/* Change the colors and other styles as needed */

/* Track (background) */
::-webkit-scrollbar {
  width: 10px;
  background-color: #8b0100; /* Gray background color */
}

/* Handle (thumb) */
::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* White thumb color */
  border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #d0d0d0; /* Light gray thumb color on hover */
}

/* Change the appearance for other browsers (Firefox, etc.) */
/* You can use standard CSS properties, but not all browsers support it */

/* For Safari, you can also use the standard scrollbar properties */

.App {
  text-align: center;
  /* Text color */
  color: black;
  background-color: #8b0100;
}

* {
  /* Track (background) */
  /* For Firefox, Microsoft Edge, and other browsers */
  scrollbar-width: thin; /* "thin" or "auto" */
  scrollbar-color: #f5f5f5 #8b0100; /* Gray background and white thumb color */
}
